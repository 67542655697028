// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wishlist-row {
  background-color: #99e6ff;
  color: #282c34;
}

.wishlist-row .wishlist-totals {
  float: right;
}

@media only screen and (max-width: 575px){
  .wishlist-row {
    text-align: center;
  }

  .wishlist-row .wishlist-totals {
    float: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/WishListRow/WishListRow.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE;IACE,kBAAkB;EACpB;;EAEA;IACE,WAAW;EACb;AACF","sourcesContent":[".wishlist-row {\n  background-color: #99e6ff;\n  color: #282c34;\n}\n\n.wishlist-row .wishlist-totals {\n  float: right;\n}\n\n@media only screen and (max-width: 575px){\n  .wishlist-row {\n    text-align: center;\n  }\n\n  .wishlist-row .wishlist-totals {\n    float: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
