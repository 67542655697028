// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item-list .grid-header {
  padding: 20px 0px;
  font-weight: bold;
  text-align: center;
  background-color: #282c34;
  color: white;
}
  
.item-list .item-row > div {
  padding-top: 10px;
  padding-bottom: 10px;
}
  
.item-list .item-row:nth-child(odd) > div {
  background-color: #4e5565;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/ItemList/ItemList.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb","sourcesContent":[".item-list .grid-header {\n  padding: 20px 0px;\n  font-weight: bold;\n  text-align: center;\n  background-color: #282c34;\n  color: white;\n}\n  \n.item-list .item-row > div {\n  padding-top: 10px;\n  padding-bottom: 10px;\n}\n  \n.item-list .item-row:nth-child(odd) > div {\n  background-color: #4e5565;\n  color: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
