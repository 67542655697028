import "./LoggedInUser.css";
import { Col, Container, Row, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons/faGoogle";
import { faRightFromBracket }  from "@fortawesome/free-solid-svg-icons/faRightFromBracket";

function LoggedInUser(props) {
  return (
    <Container className="logged-in-user">
      <Row>
        <Col className="d-flex align-items-center justify-content-center">
          <img src={props.profile.picture} />
          <span>{props.profile.name}</span>
          <FontAwesomeIcon icon={faGoogle} size="2xs" />  
          <Button onClick={() => props.logOut()} variant="link">      
            <FontAwesomeIcon icon={faRightFromBracket} />
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default LoggedInUser;