import './ItemList.css';
import './ItemListSmall.css';
import { Container, Row, Col } from 'react-bootstrap';
import ItemName from '../ItemName/ItemName';
import ItemImage from '../ItemImage/ItemImage';
import ItemDucatCost from "../ItemCost/ItemDucatCost";
import ItemCreditCost from "../ItemCost/ItemCreditCost";

function ItemListSmall(props) {
  return (
    <Container fluid className="item-list item-list-small">
      <Row>
        <Col xs={8} className="grid-header">Item</Col>
        <Col xs={4} className="grid-header">Cost</Col>
      </Row>
      {props.items.map(item => {
        return (
          <Row className="item-row">
            <Col xs={4} className="d-flex align-items-center justify-content-center">
              <ItemImage item={item} />
            </Col>
            <Col xs={4} className="d-flex align-items-center">
              <ItemName item={item} />
            </Col>
            <Col xs={4} className="d-flex align-items-center justify-content-center">
              <div>
                <div>
                  <ItemDucatCost cost={item.DucatCost} />
                </div>
                <div>
                  <ItemCreditCost cost={item.CreditCost} />
                </div>
              </div>
            </Col>
          </Row>
        )
      })}
    </Container>
  );
}

export default ItemListSmall;