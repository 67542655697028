// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button.wishlist-add.btn.btn-secondary {
  /* color: #008000; */
  /* background-color: #85e085; */
}

button.wishlist-remove.btn.btn-secondary {
  /* color: #cc0000; */
  /* background-color: #ffad99; */
  background-color: #99e6ff;
}

.check-removal-modal .modal-content {
  background-color: #a9afbc;
  padding-left: 0px;
}

.check-removal-modal .modal-header {
  color: white;
  background-color: #282c34;
  border-bottom: 1px solid #4e5565;
}

.check-removal-modal .modal-header .btn-close {
  color: white;
}

.check-removal-modal .modal-footer {
  border-top: 1px solid #4e5565;
}

.check-removal-modal .btn-primary {
  color: white;
  background-color: #4e5565;
}

.check-removal-modal .btn-secondary {
  color: #4e5565;
  background-color: #99e6ff;
}`, "",{"version":3,"sources":["webpack://./src/components/ItemActions/ItemActions.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,+BAA+B;AACjC;;AAEA;EACE,oBAAoB;EACpB,+BAA+B;EAC/B,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,yBAAyB;EACzB,gCAAgC;AAClC;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,YAAY;EACZ,yBAAyB;AAC3B;;AAEA;EACE,cAAc;EACd,yBAAyB;AAC3B","sourcesContent":["button.wishlist-add.btn.btn-secondary {\n  /* color: #008000; */\n  /* background-color: #85e085; */\n}\n\nbutton.wishlist-remove.btn.btn-secondary {\n  /* color: #cc0000; */\n  /* background-color: #ffad99; */\n  background-color: #99e6ff;\n}\n\n.check-removal-modal .modal-content {\n  background-color: #a9afbc;\n  padding-left: 0px;\n}\n\n.check-removal-modal .modal-header {\n  color: white;\n  background-color: #282c34;\n  border-bottom: 1px solid #4e5565;\n}\n\n.check-removal-modal .modal-header .btn-close {\n  color: white;\n}\n\n.check-removal-modal .modal-footer {\n  border-top: 1px solid #4e5565;\n}\n\n.check-removal-modal .btn-primary {\n  color: white;\n  background-color: #4e5565;\n}\n\n.check-removal-modal .btn-secondary {\n  color: #4e5565;\n  background-color: #99e6ff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
