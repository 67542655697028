// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-header-wrapper {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(15px + 2vmin);
  color: white;
  /* padding-top: 10px; */
}

.logo-col {
  padding: 10px;
}

.title-col p {
  margin-bottom: 0px;
}

.baro-logo {
  max-width: 100%;
  max-height: 100px;
}

.prime-logo {
 width: 20px;
 margin-right: 5px;
}

.created-by {
  font-size: 10pt;
  margin-bottom: 20px;
}

.google-login {
  padding-bottom: 20px;  
}

.google-login .btn {
  background-color: #d4d7dd;
  border-color: #d4d7dd;
  color: #4e5565;
}

.google-login .fa-google {
  margin-right: 10px;
}

.user-options {
  position: absolute;
  top: 5px;
  right: 5px;
}

.user-options .btn {
  padding: 0px 5px;
}

@media only screen and (max-width: 767px){
  .baro-logo {
    max-height: 50px;
  }

  .created-by {
    margin-bottom: 10px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/AppHeader/AppHeader.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;EACZ,uBAAuB;AACzB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;CACC,WAAW;CACX,iBAAiB;AAClB;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,UAAU;AACZ;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE;IACE,gBAAgB;EAClB;;EAEA;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".app-header-wrapper {\n  background-color: #282c34;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(15px + 2vmin);\n  color: white;\n  /* padding-top: 10px; */\n}\n\n.logo-col {\n  padding: 10px;\n}\n\n.title-col p {\n  margin-bottom: 0px;\n}\n\n.baro-logo {\n  max-width: 100%;\n  max-height: 100px;\n}\n\n.prime-logo {\n width: 20px;\n margin-right: 5px;\n}\n\n.created-by {\n  font-size: 10pt;\n  margin-bottom: 20px;\n}\n\n.google-login {\n  padding-bottom: 20px;  \n}\n\n.google-login .btn {\n  background-color: #d4d7dd;\n  border-color: #d4d7dd;\n  color: #4e5565;\n}\n\n.google-login .fa-google {\n  margin-right: 10px;\n}\n\n.user-options {\n  position: absolute;\n  top: 5px;\n  right: 5px;\n}\n\n.user-options .btn {\n  padding: 0px 5px;\n}\n\n@media only screen and (max-width: 767px){\n  .baro-logo {\n    max-height: 50px;\n  }\n\n  .created-by {\n    margin-bottom: 10px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
