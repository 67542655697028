// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item-list-small {
  display:none;
  visibility: hidden;  
}

@media only screen and (max-width: 767px){
  .item-list-small {
    display: block;
    visibility: visible;  
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ItemList/ItemListSmall.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE;IACE,cAAc;IACd,mBAAmB;EACrB;AACF","sourcesContent":[".item-list-small {\n  display:none;\n  visibility: hidden;  \n}\n\n@media only screen and (max-width: 767px){\n  .item-list-small {\n    display: block;\n    visibility: visible;  \n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
