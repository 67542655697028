import { useEffect, useState } from "react";
import ItemDucatCost from "../ItemCost/ItemDucatCost";
import ItemCreditCost from "../ItemCost/ItemCreditCost";
import ItemHelpers from "../../helpers/ItemHelpers";
import "./CostTotals.css";

function CostTotals(props) {
  const [ ducatCost, setDucatCost ] = useState(0);
  const [ creditCost, setCreditCost ] = useState(0);
  
  useEffect(() => {
    setDucatCost(getDucatTotal(props.data));
    setCreditCost(getCreditTotal(props.data));
  }, [props.data]);

  const getTotal = function(data, prop) {
    return ItemHelpers.getTotalCost(data, prop);
  };

  const getDucatTotal = function(data) {
    return data && data.length ? getTotal(data, "DucatCost") : 0;
  }

  const getCreditTotal = function(data) {
    return data && data.length ? getTotal(data, "CreditCost") : 0;
  }

  return (
    <div className={`cost-totals ${props.className}`}>
      <span><b>{props.title}</b></span>
      <ItemDucatCost cost={ducatCost} />
      <ItemCreditCost cost={creditCost} />
    </div>
  );
}

export default CostTotals;