// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item-type {
  font-size: 8pt;
  color: #d4d7dd;
  background-color: #282c34;
  border-radius: 5px;
  padding: 0px 5px 3px 5px;
}

.item-type-secondary {
  margin-left: 5px;
}

.action-buttons .full-action-buttons button.btn {
  padding: 5px 10px;
  margin-top: 10px;
  color: #282c34;
  background-color: #a9afbc;
}

.action-buttons button.btn.limited-action-buttons {
  padding: 0px;
}

@media only screen and (max-width: 575px){
  .item-type {
    display: block;
    width: -moz-fit-content;
    width: fit-content;
  }

  .item-type-secondary {
    margin-left: 0px;
    margin-top: 5px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ItemName/ItemName.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,cAAc;EACd,yBAAyB;EACzB,kBAAkB;EAClB,wBAAwB;AAC1B;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,cAAc;EACd,yBAAyB;AAC3B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE;IACE,cAAc;IACd,uBAAkB;IAAlB,kBAAkB;EACpB;;EAEA;IACE,gBAAgB;IAChB,eAAe;EACjB;AACF","sourcesContent":[".item-type {\n  font-size: 8pt;\n  color: #d4d7dd;\n  background-color: #282c34;\n  border-radius: 5px;\n  padding: 0px 5px 3px 5px;\n}\n\n.item-type-secondary {\n  margin-left: 5px;\n}\n\n.action-buttons .full-action-buttons button.btn {\n  padding: 5px 10px;\n  margin-top: 10px;\n  color: #282c34;\n  background-color: #a9afbc;\n}\n\n.action-buttons button.btn.limited-action-buttons {\n  padding: 0px;\n}\n\n@media only screen and (max-width: 575px){\n  .item-type {\n    display: block;\n    width: fit-content;\n  }\n\n  .item-type-secondary {\n    margin-left: 0px;\n    margin-top: 5px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
