// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.export-button {
  padding: 0px 5px;
  margin-left: 10px;
  border: 1px solid #282c34;
  border-radius: 5px;
}

button.export-button.btn {
  color: #282c34;
  font-size: 10pt;
}

button.export-button.btn .fa-file-excel{
  color: #282c34;
  font-size: 12pt;
  /* margin-right: 5px; */
}`, "",{"version":3,"sources":["webpack://./src/components/ExportButton/ExportButton.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,uBAAuB;AACzB","sourcesContent":[".export-button {\n  padding: 0px 5px;\n  margin-left: 10px;\n  border: 1px solid #282c34;\n  border-radius: 5px;\n}\n\nbutton.export-button.btn {\n  color: #282c34;\n  font-size: 10pt;\n}\n\nbutton.export-button.btn .fa-file-excel{\n  color: #282c34;\n  font-size: 12pt;\n  /* margin-right: 5px; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
