// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cost-totals img.currency-image {
  margin-right: 2px;
  margin-left: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/CostTotals/CostTotals.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,gBAAgB;AAClB","sourcesContent":[".cost-totals img.currency-image {\n  margin-right: 2px;\n  margin-left: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
