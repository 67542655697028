// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tool-bar {
  padding: 10px;
  /* background-color: #596273; */
  background-color: #a9afbc;
}

.tool-bar .search-box {
  width: 100%;
  max-width: 400px;
}

.tool-bar .type-list {
  float: right;
  width: 100%;
  max-width: 400px;
}

@media only screen and (max-width: 575px){
  .tool-bar {
    text-align: center;
  }
  .tool-bar .search-box {
    margin-bottom: 10px;
  }
  .tool-bar .type-list {
    float: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ToolBar/ToolBar.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,+BAA+B;EAC/B,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE;IACE,kBAAkB;EACpB;EACA;IACE,mBAAmB;EACrB;EACA;IACE,WAAW;EACb;AACF","sourcesContent":[".tool-bar {\n  padding: 10px;\n  /* background-color: #596273; */\n  background-color: #a9afbc;\n}\n\n.tool-bar .search-box {\n  width: 100%;\n  max-width: 400px;\n}\n\n.tool-bar .type-list {\n  float: right;\n  width: 100%;\n  max-width: 400px;\n}\n\n@media only screen and (max-width: 575px){\n  .tool-bar {\n    text-align: center;\n  }\n  .tool-bar .search-box {\n    margin-bottom: 10px;\n  }\n  .tool-bar .type-list {\n    float: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
