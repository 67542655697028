import "./WishListRow.css";
import { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { UserWishList } from '../../contexts/UserWishList';
import { UserContext } from '../../contexts/UserContext';
import ExportButton from "../ExportButton/ExportButton";
import CostTotals from "../CostTotals/CostTotals";

function WishListRow(props){
  const { userProfile } = useContext(UserContext);
  const { userWishList } = useContext(UserWishList);

  return (
    <Container className="wishlist-row" fluid>
      <Row>
        <Col sm={6}>
          <span><b>{userProfile.name}'s Wishlist</b></span>
          <ExportButton />
        </Col>
        <Col sm={6}>
          <CostTotals className="wishlist-totals" title="Grand Total:" data={userWishList} />
        </Col>
      </Row>
    </Container>
  );    
}

export default WishListRow;