const ArrayHelpers = {

  distinct:  function (value, index, array) {
    return array.indexOf(value) === index;
  },

  sort: function (a, b) {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  },

}

export default ArrayHelpers;