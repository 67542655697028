// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `img.item-image {
  max-width: 100%;
  max-height: 100%;
  border-radius: 5px;
  height: auto;
}

.item-image-wrapper {
  /* max-height: 150px; */
  max-width: 150px;
}`, "",{"version":3,"sources":["webpack://./src/components/ItemImage/ItemImage.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,uBAAuB;EACvB,gBAAgB;AAClB","sourcesContent":["img.item-image {\n  max-width: 100%;\n  max-height: 100%;\n  border-radius: 5px;\n  height: auto;\n}\n\n.item-image-wrapper {\n  /* max-height: 150px; */\n  max-width: 150px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
