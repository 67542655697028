import './ItemImage.css';

function ItemImage(props) {
  const handleImageError = function(event) {
    event.target.src = "./images/clem.png";
  }

  return (
    <div className="item-image-wrapper"><img className="item-image" src={props.item.Image} onError={handleImageError} alt="item" /></div>
  );
}
  
export default ItemImage;