import "./App.css";
import { useState } from "react";
import { Routes, Route} from "react-router-dom"
import Home from "./pages/Home/Home"
import Privacy from "./pages/Privacy/Privacy"
import { UserContext } from "./contexts/UserContext";
import { UserWishList } from "./contexts/UserWishList";

function App() {
  const [userProfile, setUserProfile] = useState(JSON.parse(localStorage.getItem("userContext")) || null);
  const [userWishList, setUserWishList] = useState(JSON.parse(localStorage.getItem("userWishList")) || null);

  return (
    <UserContext.Provider value={{ userProfile: userProfile, setUserProfile: setUserProfile }}>
      <UserWishList.Provider value={{ userWishList: userWishList, setUserWishList: setUserWishList }}>
        <div className="App">
          <Routes>
            <Route path="/" element={ <Home/> } />
            <Route path="/wishlist" element={ <Home isWishList /> } />
            <Route path="privacy" element={ <Privacy/> } />        
          </Routes>
        </div>
      </UserWishList.Provider>
    </ UserContext.Provider>
  )
}

export default App;