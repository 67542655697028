// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-tabs-row {
  background-color: #282c34;
}

.menu-tabs {
  margin-bottom: 0px;
  border-bottom: 0px;
}

.nav-item {
  background-color: #4e5565;
  border-radius: 5px 5px 0px 0px;
}

.menu-tabs.nav-tabs .nav-link {
  color: #a9afbc;
}

.menu-tabs.nav-tabs .nav-link.active {
  color: #282c34;
  background-color: #a9afbc;
  border: 1px solid #a9afbc;
}`, "",{"version":3,"sources":["webpack://./src/components/MenuTabs/MenuTabs.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,8BAA8B;AAChC;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,yBAAyB;EACzB,yBAAyB;AAC3B","sourcesContent":[".menu-tabs-row {\n  background-color: #282c34;\n}\n\n.menu-tabs {\n  margin-bottom: 0px;\n  border-bottom: 0px;\n}\n\n.nav-item {\n  background-color: #4e5565;\n  border-radius: 5px 5px 0px 0px;\n}\n\n.menu-tabs.nav-tabs .nav-link {\n  color: #a9afbc;\n}\n\n.menu-tabs.nav-tabs .nav-link.active {\n  color: #282c34;\n  background-color: #a9afbc;\n  border: 1px solid #a9afbc;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
