import './Home.css';
import { useState, useEffect, useContext } from "react";
import { Pagination } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ArrayHelpers from '../../helpers/ArrayHelpers';
import ItemListMedium from '../../components/ItemList/ItemListMedium';
import ItemListSmall from '../../components/ItemList/ItemListSmall';
import ToolBar from "../../components/ToolBar/ToolBar";
import PaginationRow from '../../components/PaginationRow/PaginationRow';
import AppHeader from '../../components/AppHeader/AppHeader';
import Loader from '../../components/Loader/Loader';
import WishListRow from '../../components/WishListRow/WishListRow';
import { UserWishList } from '../../contexts/UserWishList';
import MenuTabs from '../../components/MenuTabs/MenuTabs';

function Home(props) {
  const pageSize = 50;
  const { userWishList } = useContext(UserWishList);
  const [allItems, setAllItems] = useState([]);
  const [filteredAndPagedItems, setFilteredAndPagedItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [typeFilterCount, setTypeFilterCount] = useState([]);
  const [searchTerm, setSearchTerm] = useState(null);
  const [typeFilter, setTypeFilter] = useState(null);
  const [types, setTypes] = useState([]);
  const [smallPagingItems, setSmallPagingItems] = useState([]);
  const [mediumPagingItems, setMediumPagingItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setAllItems([]);
    setTypeFilter(null);

    if(props.isWishList) {
      setAllItems(userWishList || []);
      setLoading(false);
      return;
    }
    
    setLoading(true);
    const url = `${process.env.REACT_APP_API_BASE_URL}/baro/items`;

    fetch(url)
      .then(response => response.json())
      .then(items => {
        setAllItems(items);    
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [props.isWishList]);

  useEffect(() => {
    if(props.isWishList) {
      setAllItems(userWishList || []);
      setLoading(false);
      return;
    }
  }, [userWishList]);

  useEffect(() => {
    const updateTypesList = function() {
      const types = allItems.map(x => x.Type)
        .filter(ArrayHelpers.distinct)
        .sort(ArrayHelpers.sort);
      setTypes(types);
    }

    updateTypesList();
  }, [allItems]);

  useEffect(() => {
    const updateData = function() {
      const hasSearchValue = searchTerm && searchTerm.length > 1;
      const hasTypeFilter = typeFilter && typeFilter.length > 1;
      const filteredByType = hasTypeFilter
        ? allItems.filter(x => x.Type.toLowerCase() === typeFilter.toLowerCase())
        : allItems;
      const filteredByTypeAndSearch = hasSearchValue 
        ? filteredByType.filter(x => x.Name.toLowerCase().indexOf(searchTerm) > -1)
        : filteredByType;
      const filteredAndPaged = setupAndApplyPaging(filteredByTypeAndSearch);
  
      setTypeFilterCount(filteredByType.length);
      setFilteredItems(filteredByTypeAndSearch);
      setFilteredAndPagedItems(filteredAndPaged);
    };

    const setupAndApplyPaging = function(data) {
      const pages = [...Array(Math.ceil(data.length / pageSize)).keys()];
      const mediumItems = pages.map(number => createPagingItem(number));
      const smallRange = 6;
      const smallModifier = smallRange / 2;
      const smallStart = Math.max(currentPage - smallModifier, 1) - 1;
      const smallEnd = Math.min(currentPage + smallModifier, pages.length);
      const smallItems = [...pagingBackArrows(), ...mediumItems.slice(smallStart, smallEnd), ...pagingForwardArrows(pages.length)];
      const skip = (currentPage - 1) * pageSize;
      const take = skip + pageSize;
      const paged = data.length > pageSize 
        ? data.slice(skip, take) 
        : data;
  
      setSmallPagingItems(smallItems);
      setMediumPagingItems(mediumItems);
  
      return paged;
    };

    const pagingBackArrows = function() {
      return [
        (<Pagination.First onClick={() => pageClicked(1)} />),
        (<Pagination.Prev onClick={() => pageClicked(Math.max(currentPage - 1, 1))} />)
      ];
    }
  
    const pagingForwardArrows = function(pageCount) {
      return [
        (<Pagination.Next onClick={() => pageClicked(Math.min(currentPage + 1, pageCount))} />),
        (<Pagination.Last onClick={() => pageClicked(pageCount)} />)
      ];
    }
  
    const createPagingItem = function(number) {
      return (<Pagination.Item key={number + 1} active={(number + 1) === currentPage} onClick={() => pageClicked(number + 1)}>{number + 1}</Pagination.Item>);
    }

    updateData();
  }, [allItems, searchTerm, typeFilter, currentPage]);

  const searchChanged = function(event) {
    const newTerm = (event.target.value || "").toLowerCase();
    setSearchTerm(newTerm);
    setCurrentPage(1);
  }

  const typeChanged = function(event) {
    const newType = (event.target.value || "").toLowerCase();
    setTypeFilter(newType);
    setCurrentPage(1);
  }

  const pageClicked = function(newPage) {
    setCurrentPage(newPage);
  }

  return (<>
    <div className="App">
      <header className="App-header">
        <AppHeader />
      </header>
    </div>    
    <div>
      <MenuTabs isWishList={props.isWishList} />
    </div>
    <div className="body-wrapper">
      <ToolBar types={types} searchChanged={searchChanged} typeChanged={typeChanged} />

      {props.isWishList ? <WishListRow /> : null }

      <PaginationRow 
        filteredItems={filteredItems}
        typeFilterCount={typeFilterCount}
        typeFilter={typeFilter}
        visibleCount={Math.min(pageSize, filteredAndPagedItems.length)} 
        smallPagingItems={smallPagingItems} 
        mediumPagingItems={mediumPagingItems} />

      {/* Only shows for SMALLER screens (hide/show handled by CSS) */}
      <ItemListSmall items={filteredAndPagedItems} />
      
      {/* Only shows for LARGER screens (hide/show handled by CSS) */}
      <ItemListMedium items={filteredAndPagedItems} />

      <Loader loading={loading} />
    </div>
  </>);
}

export default Home;
