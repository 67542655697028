import "./Loader.css";
import { Container, Col, Row, Spinner } from 'react-bootstrap';

function Loader(props) {
  return (
    <Container fluid>
      <Row>
        <Col xs={12} className="d-flex align-items-center justify-content-center">
          {props.loading ? 
            <div className="loader-wrapper">
              {/* <div>
                <img className="alad-v-dance" src="./images/allad-v-warframe.gif" />
              </div> */}
              <Spinner className="loader" animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
              <p>Loading...</p>
            </div>
          : null}
        </Col>
      </Row>
    </Container>
  );
}

export default Loader;