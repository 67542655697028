import "./ItemActions.css";
import { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faMinus } from "@fortawesome/free-solid-svg-icons/faMinus";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck"
import { faX } from "@fortawesome/free-solid-svg-icons/faX"
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { UserWishList } from "../../contexts/UserWishList";
import { UserContext } from "../../contexts/UserContext";
import ItemName from "../ItemName/ItemName";
import ItemImage from "../ItemImage/ItemImage";

function ItemActions(props) {
  const { userWishList, setUserWishList } = useContext(UserWishList);
  const { userProfile } = useContext(UserContext);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const addToWishList = function() {
    const newList = [...userWishList, props.item];
    saveWishList(newList);
  };

  const removeFromWishList = function() {
    handleClose();
    const newList = [...userWishList];
    const indexToRemove = newList.findIndex(x => x.Name === props.item.Name);
    if(indexToRemove > -1){
      newList.splice(indexToRemove, 1);
    }
    saveWishList(newList);
  }

  const saveWishList = function(newList) {
    setUserWishList(newList);
    localStorage.setItem("userWishList", JSON.stringify(newList));

    fetch(`${process.env.REACT_APP_API_BASE_URL}/wishlist/save`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email: userProfile.email,
        items: newList
      })
    });    
  }

  const itemExists = function() {
    return userWishList && userWishList.findIndex(x => x.Name === props.item.Name) > -1;
  }

  return (<>
    { !itemExists() 
      ? <Button className="wishlist-add" onClick={() => addToWishList()} variant="secondary">
          <FontAwesomeIcon icon={faPlus} size="sm" />
        </Button> 
      : <Button className="wishlist-remove" onClick={() => handleShow()} variant="secondary">
          <FontAwesomeIcon icon={faMinus} size="sm" />
        </Button>
    }
    <Modal className="check-removal-modal" show={show} onHide={handleClose} centered={true} style={{paddingLeft: "0px"}}>
      <Modal.Header>
        <Modal.Title>Are you sure?</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Container fluid>
          <Row>
            <Col>
              <p>Are you sure you want to remove this item from your wishlist?</p>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex align-items-center justify-content-center">
              <ItemImage item={props.item} />
            </Col>
            <Col className="d-flex align-items-center">
              <ItemName item={props.item} hideActions={true} />
            </Col>
          </Row>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={() => removeFromWishList()}>
          <FontAwesomeIcon icon={faCheck} />
        </Button>
        <Button variant="primary" onClick={() => handleClose()}>
          <FontAwesomeIcon icon={faX} />
        </Button>
      </Modal.Footer>
    </Modal>
  </>);
}

export default ItemActions;