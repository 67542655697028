import "./ExportButton.css";
import * as XLSX from "xlsx/xlsx.mjs";
import { useContext } from "react";
import { UserWishList } from "../../contexts/UserWishList";
import { UserContext } from "../../contexts/UserContext";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";

function ExportButton() {
  const { userWishList } = useContext(UserWishList);
  const { userProfile } = useContext(UserContext);

  const exportWishlist = function() {
    var data = [...userWishList].forEach(x => {delete x._id; delete x.Image});
    var ws = XLSX.utils.json_to_sheet(userWishList);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Wishlist");
    XLSX.writeFile(wb, `${userProfile.name.replace(/\s/g,"_")}_Wishlist.xlsx`);
  }

  return (
    <Button className="export-button" variant="Link" onClick={() => exportWishlist()}>
      <FontAwesomeIcon icon={faFileExcel} size="md" />
      {/* Export */}
    </Button>
  );
}

export default ExportButton;