// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item-list-medium {
  max-width: 1200px;
}

@media only screen and (max-width: 767px){
  .item-image-col {
    text-align: center;
  }
  .item-name-col {
    text-align: center;
    padding-top: 10px;
  }
  .item-list-medium {
    display: none;
    visibility: hidden;  
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ItemList/ItemListMedium.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE;IACE,kBAAkB;EACpB;EACA;IACE,kBAAkB;IAClB,iBAAiB;EACnB;EACA;IACE,aAAa;IACb,kBAAkB;EACpB;AACF","sourcesContent":[".item-list-medium {\n  max-width: 1200px;\n}\n\n@media only screen and (max-width: 767px){\n  .item-image-col {\n    text-align: center;\n  }\n  .item-name-col {\n    text-align: center;\n    padding-top: 10px;\n  }\n  .item-list-medium {\n    display: none;\n    visibility: hidden;  \n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
