import "./MenuTabs.css";
import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";

function MenuTabs(props) {
  const { userProfile } = useContext(UserContext);
  const navigate = useNavigate();

  const handleClick = function(key) {
    navigate(key);
  }

  return (
    <Container className="menu-tabs-row" fluid>
      <Row>
        <Col>
          { userProfile ? 
            <Tabs defaultActiveKey={props.isWishList ? "/wishlist" : "/"} id="uncontrolled-tab-example" className="menu-tabs" onSelect={(key) => handleClick(key)}>
              <Tab eventKey="/" title="Full Inventory"></Tab>
              <Tab eventKey="/wishlist" title="My Wishlist"></Tab>
            </Tabs>
            : null 
          }
        </Col>
      </Row>
    </Container>
  );
}

export default MenuTabs;