import './ItemList.css';
import './ItemListMedium.css';
import { Container, Row, Col } from 'react-bootstrap';
import ItemName from '../ItemName/ItemName';
import ItemImage from '../ItemImage/ItemImage';
import ItemDucatCost from "../ItemCost/ItemDucatCost";
import ItemCreditCost from "../ItemCost/ItemCreditCost";

function ItemListMedium(props) {
  return (
    <Container fluid className="item-list item-list-medium">
      <Row>
        <Col xs={4} md={6} className="grid-header">Item</Col>
        <Col xs={4} md={3} className="grid-header">Ducats</Col>
        <Col xs={4} md={3} className="grid-header">Credits</Col>
      </Row>
      {props.items.map(item => {
        return (
          <Row className="item-row">
            <Col xs={4} md={6}>
              <Container>
                <Row>
                  <Col md={5} className="item-image-col d-flex align-items-center justify-content-center">
                    <ItemImage item={item} />
                  </Col>
                  <Col md={7} className="item-name-col d-flex align-items-center">
                    <ItemName item={item} />
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col xs={4} md={3} className="d-flex align-items-center justify-content-center">
              <ItemDucatCost cost={item.DucatCost} />
            </Col>
            <Col xs={4} md={3} className="d-flex align-items-center justify-content-center">
              <ItemCreditCost cost={item.CreditCost} />
            </Col>
          </Row>
        )
      })}
    </Container>
  );
}

export default ItemListMedium;