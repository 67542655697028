import "./ItemCost.css";
import NumberHelper from "../../helpers/NumberHelper";

function ItemCreditCost(props) {
  return (<>
    <img className="currency-image" src="./images/credits.png" alt="credits"  />
    <span className="align-middle">{NumberHelper.formatWithCommas(props.cost || 0)}</span>
  </>);
}
  
export default ItemCreditCost;