import './ItemName.css';
import { useContext } from "react";
import { Button, ButtonGroup, Container, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons/faUpRightFromSquare';
import { UserContext } from '../../contexts/UserContext';
import ItemActions from '../ItemActions/ItemActions';

function ItemName(props) {
  const { userProfile } = useContext(UserContext);

  const getTypes = function(type) {
    return type.split("(").map(x => {
      return x.trim()
              .replace(/\s/g, "")
              .replace(/\)/g, "");
    });
  }

  const openWiki = function(url) {
    window.open(url);
  }

  return (
    <Container className="item-name">
      <Row>
        <Col>
          <div>
            { getTypes(props.item.Type).map((x,i) => {
              return (<span className={i > 0 ? "item-type item-type-secondary" : "item-type"}>{x}</span>);
            })}
          </div>
          {props.item.Name}
        </Col>
      </Row>
      <Row>
        <Col className="action-buttons">
          { !userProfile && !props.hideActions
            ? <Button className="limited-action-buttons" onClick={x => openWiki(props.item.Link)} variant="link">
                <FontAwesomeIcon icon={faUpRightFromSquare} size="sm" />
              </Button>
            : null
          }       

          { userProfile && !props.hideActions
            ? <ButtonGroup className="full-action-buttons">
                <ItemActions item={props.item} />
                <Button onClick={x => openWiki(props.item.Link)} variant="secondary">
                  <FontAwesomeIcon icon={faUpRightFromSquare} size="sm" />
                </Button>
              </ButtonGroup>
            : null }
        </Col>
      </Row>
    </Container>
  )
}

export default ItemName;