// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination {
  margin-bottom: 0px;
}

.pagination-row {
  padding: 10px;
}

.pagination-row .page-link {
  color: #4e5565;
  background-color: #d4d7dd;
  font-weight: bold;
}

.pagination-row .active > .page-link {
  color: #d4d7dd;
  background-color: #4e5565;
}

.pagination-small, .pagination-medium {
  margin: 0 auto;
  float: right;
}

.pagination-small {
  display: none;
  visibility: hidden;
}

.cost-totals-col {
  text-align: center;
}

.visible-item-count {
  font-size: 10pt;
  color: #d4d7dd;
  background-color: #4e5565;
  border-radius: 5px;
  padding: 0px 5px 3px 5px;
  margin-left: 10px;
}

@media only screen and (max-width: 767px){
  .pagination-small, .pagination-medium {
    margin-top: 10px;
    float: none;
  }

  .cost-totals-col {
    text-align: unset;
  }

  .cost-totals .filtered-results-cost  {
    float: left;
  }
}

@media only screen and (max-width: 575px){
  
}`, "",{"version":3,"sources":["webpack://./src/components/PaginationRow/PaginationRow.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,cAAc;EACd,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,yBAAyB;AAC3B;;AAEA;EACE,cAAc;EACd,YAAY;AACd;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,yBAAyB;EACzB,kBAAkB;EAClB,wBAAwB;EACxB,iBAAiB;AACnB;;AAEA;EACE;IACE,gBAAgB;IAChB,WAAW;EACb;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,WAAW;EACb;AACF;;AAEA;;AAEA","sourcesContent":[".pagination {\n  margin-bottom: 0px;\n}\n\n.pagination-row {\n  padding: 10px;\n}\n\n.pagination-row .page-link {\n  color: #4e5565;\n  background-color: #d4d7dd;\n  font-weight: bold;\n}\n\n.pagination-row .active > .page-link {\n  color: #d4d7dd;\n  background-color: #4e5565;\n}\n\n.pagination-small, .pagination-medium {\n  margin: 0 auto;\n  float: right;\n}\n\n.pagination-small {\n  display: none;\n  visibility: hidden;\n}\n\n.cost-totals-col {\n  text-align: center;\n}\n\n.visible-item-count {\n  font-size: 10pt;\n  color: #d4d7dd;\n  background-color: #4e5565;\n  border-radius: 5px;\n  padding: 0px 5px 3px 5px;\n  margin-left: 10px;\n}\n\n@media only screen and (max-width: 767px){\n  .pagination-small, .pagination-medium {\n    margin-top: 10px;\n    float: none;\n  }\n\n  .cost-totals-col {\n    text-align: unset;\n  }\n\n  .cost-totals .filtered-results-cost  {\n    float: left;\n  }\n}\n\n@media only screen and (max-width: 575px){\n  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
