import "./PaginationRow.css";
import { Container, Row, Col, Pagination } from 'react-bootstrap';
import CostTotals from "../CostTotals/CostTotals";

function PaginationRow(props) {

  const toTitleCase = function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  }

  const getTypeDisplay = function() {
    return `${(props.typeFilter || "All").replace(/\w\S*/g, toTitleCase)}`;
  }

  const getResultsCount = function() {
    return props.filteredItems.length || 0;
  }

  return (
    <Container className="pagination-row" fluid>
      <Row>
        <Col md={4} className="d-flex align-items-center">
          <div className="item-count">
            <span><b>Results:</b> {getResultsCount()}</span>
            {props.typeFilterCount !== getResultsCount() ? <span className="visible-item-count">{getTypeDisplay()}: {props.typeFilterCount}</span> : null }
            {props.visibleCount < getResultsCount() ? <span className="visible-item-count">Page Size: {props.visibleCount}</span> : null }
          </div>
        </Col>
        <Col md={4} className="cost-totals-col">
          <CostTotals className="filtered-results-cost" title="Total:" data={props.filteredItems} />
        </Col>
        <Col md={4} className="pagination-col">
          <div className="pagination-small">{ props.smallPagingItems.length > 1 ? <Pagination size="sm">{props.smallPagingItems}</Pagination> : null }</div>
          <div className="pagination-medium">{ props.mediumPagingItems.length > 1 ? <Pagination size="sm">{props.mediumPagingItems}</Pagination> : null }</div>
        </Col>
      </Row>
    </Container>
  );
}

export default PaginationRow;