// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-wrapper {
  margin-top: 50px;
  text-align: center;
}

.alad-v-dance {
  margin-bottom: 15px;
  width: 200px;
  height: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/Loader/Loader.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,YAAY;EACZ,YAAY;AACd","sourcesContent":[".loader-wrapper {\n  margin-top: 50px;\n  text-align: center;\n}\n\n.alad-v-dance {\n  margin-bottom: 15px;\n  width: 200px;\n  height: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
