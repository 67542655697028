import "./ToolBar.css";
import { Container, Row, Col } from 'react-bootstrap';

function ToolBar(props) {
  return (
    <Container className="tool-bar" fluid>
      <Row>
        <Col sm={6}>
          <input className="search-box" placeholder="Search..." type="text" onChange={e => props.searchChanged(e)} />
        </Col>
        <Col sm={6}>
          <select className="type-list" onChange={e => props.typeChanged(e)}>
            <option key="all" value="">All</option>
            {props.types.map((value) => (
              <option key={value} value={value}>{value}</option>
            ))}
          </select>
        </Col>
      </Row>
    </Container>
  );
}

export default ToolBar;