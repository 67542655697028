import "./AppHeader.css";
import LoggedInUser from "../LoggedInUser/LoggedInUser";
import { useState, useEffect, useContext } from "react";
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle }  from "@fortawesome/free-brands-svg-icons/faGoogle";
import { UserContext } from '../../contexts/UserContext';
import { UserWishList } from '../../contexts/UserWishList';

function AppHeader(props) {
  const [ user, setUser ] = useState(null);
  const { userProfile, setUserProfile } = useContext(UserContext);
  const { setUserWishList } = useContext(UserWishList);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) return;
    const headers = { headers: {Authorization: `Bearer ${user.access_token}`,  Accept: 'application/json'} };

    fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, headers)
      .then((res) => res.json())
      .then((profile) => {
        setUserProfile(profile);
        localStorage.setItem("userContext", JSON.stringify(profile));
      })
      .catch((err) => console.log(err));
  }, [user]);

  useEffect(() => {
    if (!userProfile) {
      setUserWishList(null);
      localStorage.removeItem("userWishList");
      return;
    }

    fetch(`${process.env.REACT_APP_API_BASE_URL}/wishlist/${userProfile.email}`)
      .then((res) => res.json())
      .then((wishlist) => {
        setUserWishList(wishlist);
        localStorage.setItem("userWishList", JSON.stringify(wishlist));
      })
      .catch((err) => console.log(err));
  }, [userProfile]);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log('Login Failed:', error)
  });

  const logOut = function() {
    googleLogout();
    setUserProfile(null);
    localStorage.removeItem("userContext");
    navigate("/");
  }

  return (
    <Container className="app-header-wrapper" fluid>
      <Row>
        <Col md={2} className="logo-col d-flex align-items-center justify-content-center">
          <img className="baro-logo" src="./images/baro-glyph.png" alt="logo" />         
        </Col>
        <Col md={10} className="title-col d-flex align-items-center justify-content-center">
          <p>Baro Ki'Teer Database</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="created-by">
            <img className="prime-logo" src="./images/primelogo.png" alt="logo" />
            Created by Stantz Prime
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="google-login">
          {userProfile 
            ? <LoggedInUser profile={userProfile} logOut={logOut} /> 
            : <Button onClick={() => login()}>
                <FontAwesomeIcon icon={faGoogle} />
                Login with Google
              </Button>
          }
        </Col>
      </Row>
    </Container>
  );
}

export default AppHeader;