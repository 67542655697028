

const ItemHelpers = {
  getTotalCost: function(data, prop) {
    return data.reduce((a,b) => {
      const ret = {};
      ret[prop] = (a[prop] || 0) + (b[prop] || 0);
      return ret;
    })[prop] || 0;
  }
};

export default ItemHelpers;