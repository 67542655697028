import "./ItemCost.css";
import NumberHelper from "../../helpers/NumberHelper";

function ItemDucatCost(props) {
  return (<>
    <img className="currency-image" src="./images/ducat.png" alt="ducats"  />
    <span className="align-middle">{NumberHelper.formatWithCommas(props.cost || 0)}</span>
  </>);
}
  
export default ItemDucatCost;