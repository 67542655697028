// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logged-in-user {
  background-color: #d4d7dd;
  border-radius: 7px;
  color: #4e5565;
  margin-top: 5px;
}

.logged-in-user img {
  width: 30px;
  border-radius: 25px;
  height: auto;
}

.logged-in-user .col{
  padding: 5px;
}

.logged-in-user span {
  margin-left: 10px;
  font-size: 10pt;
}

.logged-in-user .fa-google {
  margin-left: 10px;
  margin-right: 0px;
}`, "",{"version":3,"sources":["webpack://./src/components/LoggedInUser/LoggedInUser.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;AACnB","sourcesContent":[".logged-in-user {\n  background-color: #d4d7dd;\n  border-radius: 7px;\n  color: #4e5565;\n  margin-top: 5px;\n}\n\n.logged-in-user img {\n  width: 30px;\n  border-radius: 25px;\n  height: auto;\n}\n\n.logged-in-user .col{\n  padding: 5px;\n}\n\n.logged-in-user span {\n  margin-left: 10px;\n  font-size: 10pt;\n}\n\n.logged-in-user .fa-google {\n  margin-left: 10px;\n  margin-right: 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
